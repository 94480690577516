import React from "react";

import useDarkMode from "use-dark-mode";

import MoonOutline from "../icons/MoonOutline";
import SunnyOutline from "../icons/SunnyOutline";
import styles from "./toggle-theme.css";

export const ToggleTheme: React.FC = () => {
  const darkMode = useDarkMode();

  return (
    <>
      {/* <input
        checked={darkMode.value}
        className={styles.input}
        id="activeDarkMode"
        type="checkbox"
        onChange={darkMode.toggle}
      /> */}

      <div className={styles.container}>
        <button className={styles.button} title="Activar el modo claro" onClick={darkMode.disable}>
          <SunnyOutline className={styles.icon} />
        </button>

        <button
          className={styles.buttonToggle}
          title="Cambiar entre modo claro y oscuro"
          onClick={darkMode.toggle}
        >
          <span className={styles.toggle} />
        </button>

        {/* <label htmlFor="activeDarkMode">
          <span className={styles.toggle} />
        </label> */}

        <button className={styles.button} title="Activar el modo oscuro" onClick={darkMode.enable}>
          <MoonOutline className={styles.icon} />
        </button>
      </div>
    </>
  );
};
