import gsap from "gsap";

const useScrollTo = () => {
  let isStopped = false;

  const onWheel = () => {
    isStopped = true;
    window.removeEventListener("wheel", onWheel);
  };

  const scrollTo = (
    value: Element | number | string | null = 0,
    offset: number = 0,
    duration = 0.4,
  ) => {
    let y = 0;

    if (typeof value === "number") {
      y = value;
    } else if (typeof value === "string" || value?.nodeType === 1) {
      if (typeof value === "string") {
        value = document.querySelector(value);
      }
      if (!value) {
        console.error("scroll-to: Element not found");
      } else {
        y = window.scrollY + value.getBoundingClientRect().top;
      }
    }

    y += offset;

    window.addEventListener("wheel", onWheel);

    const target = { number: window.scrollY };

    gsap.to(target, {
      duration,
      number: y,
      onUpdate: () => {
        if (!isStopped) {
          window.scroll(0, target.number);
        }
      },
      onComplete: () => {
        isStopped = false;
        window.removeEventListener("wheel", onWheel);
      },
    });
  };

  return scrollTo;
};

export default useScrollTo;
