import React from "react";

import cn from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import useNavbar from "../../hooks/use-navbar";
import useScrollPosition from "../../hooks/use-scroll-position";
import { menuBurgerTheme } from "../../styles/global.css";
import { ButtonSpecial } from "../../ui/button-special";
import styles from "./header.css";
import { Navbar } from "./navbar";

interface Props {
  pathname: string;
}

export const Header: React.FC<Props> = ({ pathname }) => {
  const { menuIsOpen, toggleMenu } = useNavbar();

  const scrollPosition = useScrollPosition();

  const isCommonPage = pathname !== "/presupuesto" && pathname !== "/presupuesto/";

  return (
    <div
      className={cn(
        menuBurgerTheme,
        styles.container,
        scrollPosition > 26 && "is-sticky",
        menuIsOpen && "is-open",
      )}
    >
      <header className={styles.header}>
        <Link className={cn(styles.logo, "cursor-blue-opacity")} to="/">
          <StaticImage
            alt="Owl Studio"
            placeholder="none"
            src="../../images/logos/owl-primary.svg"
          />
        </Link>

        <div className={cn(styles.burger, "cursor-white-opacity")} onClick={toggleMenu}>
          <span className={styles.burgerIngredient} />
          <span className={styles.burgerIngredient} />
          <span className={styles.burgerIngredient} />
        </div>

        <Navbar pathname={pathname} />

        {isCommonPage && (
          <div className={styles.buttonWrap}>
            <ButtonSpecial text="Contactanos" to="/presupuesto" />
          </div>
        )}
      </header>
    </div>
  );
};
