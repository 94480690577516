import React from "react";

import styles from "./loading.css";

export const Loading = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.ellipsis} />
      <div className={styles.ellipsis} />
      <div className={styles.ellipsis} />
      <div className={styles.ellipsis} />
    </div>
  );
};
