import React, { useEffect } from "react";

import cn from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import useMediaQuery from "../../hooks/use-media-query";
import useNavbar from "../../hooks/use-navbar";
import { ButtonSpecial } from "../../ui/button-special";
import styles from "./header.css";
import { NavbarItem } from "./navbar-item";

interface Props {
  pathname: string;
}

export const Navbar: React.FC<Props> = ({ pathname }) => {
  // const { services, platforms } = useStaticQuery(graphql`
  //   query QueryNavbar {
  //     services: allSanityService(sort: { fields: orderRank, order: ASC }) {
  //       nodes {
  //         slug {
  //           current
  //         }
  //         menu {
  //           title
  //           description
  //         }
  //       }
  //     }
  //     platforms: allSanityPlatform(sort: { fields: orderRank, order: ASC }) {
  //       nodes {
  //         slug {
  //           current
  //         }
  //         menu {
  //           title
  //           description
  //         }
  //       }
  //     }
  //   }
  // `);

  const { menuIsOpen, closeMenu } = useNavbar();

  const isMatch = useMediaQuery("(max-width: 992px)");

  useEffect(() => {
    if (isMatch && menuIsOpen) document.documentElement.classList.add("hidden-custom-cursor");
    else document.documentElement.classList.remove("hidden-custom-cursor");

    return () => {
      document.documentElement.classList.remove("hidden-custom-cursor");
    };
  }, [isMatch, menuIsOpen]);

  // function formatSubmenuItems(items: any, urlBase: string) {
  //   return items.nodes.map(({ slug, menu }: any) => ({
  //     link: `/${urlBase}/${slug.current}`,
  //     text: menu.title,
  //     description: menu.description,
  //   }));
  // }

  const isNavbarEstimate =
    (pathname === "/presupuesto" || pathname === "/presupuesto/") && !isMatch;

  return (
    <nav className={cn(styles.navbar)}>
      <div className={styles.navbarLogo}>
        <Link className={styles.logo} to="/" onClick={closeMenu}>
          <StaticImage alt="Owl Studio" placeholder="none" src="../../images/logos/owl-white.svg" />
        </Link>
      </div>

      <ul className={styles.navbarLinks}>
        {!isNavbarEstimate && (
          <>
            <NavbarItem
              link="/shopify"
              // submenu={formatSubmenuItems(services, "servicios")}
              text="Shopify"
            />

            <NavbarItem
              link="/headless"
              // submenu={formatSubmenuItems(platforms, "ecommerce")}
              text="Headless"
            />
          </>
        )}

        <NavbarItem
          link="/testimonios"
          text={!isNavbarEstimate ? "Testimonios" : "Testimonios de nuestros clientes"}
        />

        {!isNavbarEstimate && <NavbarItem link="/nosotros" text="Nosotros" />}
      </ul>

      <div className={styles.navbarButton}>
        <ButtonSpecial
          text="Pedí tu presupuesto"
          to="/presupuesto"
          variant="invert"
          onClick={closeMenu}
        />
      </div>
    </nav>
  );
};
