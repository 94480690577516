import React from "react";

import cn from "clsx";

import styles from "./container.css";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "home" | "hero" | "solid" | "gradient";
}

export const Container: React.FC<ContainerProps> = ({
  children,
  variant = "default",
  ...props
}) => {
  return (
    <div {...props} className={cn(styles.container[variant], props.className)}>
      {children}
    </div>
  );
};
