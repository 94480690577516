import React from "react";

import cn from "clsx";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { ButtonSpecial } from "../../ui/button-special";
import Instagram from "../../ui/icons/Instagram";
import LinkedIn from "../../ui/icons/LinkedIn";
import { ToggleTheme } from "../../ui/toggle-theme";
import { Container } from "../container";
import { EstimateItem } from "./estimateItem";
import styles from "./footer.css";

interface Props {
  pathname: string;
}

export const Footer: React.FC<Props> = ({ pathname }) => {
  const { cta } = useStaticQuery(graphql`
    query CtaFooter {
      cta: sanityBlockCtaFooter {
        title
        description
        button
      }
    }
  `);

  const isCommonPage = pathname !== "/presupuesto" && pathname !== "/presupuesto/";

  return (
    <footer>
      {isCommonPage && (
        <Container className={styles.ctaFooter}>
          <p className={styles.ctaFooterTitle}>{cta.title}</p>
          <p className={styles.ctaFooterText}>{cta.description}</p>
          <ButtonSpecial text={cta.button} to="/presupuesto" />
        </Container>
      )}

      <div
        className={cn(
          styles.footerWrap,
          isCommonPage || styles.footerEstimate,
          "cursor-white-opacity",
        )}
      >
        {isCommonPage && (
          <div className={styles.footerLogoOwl}>
            <StaticImage
              alt="Owl Studio"
              className={styles.footerLogoOwl}
              src="../../images/logos/owl-white.svg"
            />
          </div>
        )}

        <div className={styles.footerLogoCace}>
          <StaticImage alt="CACE" src="../../images/logos/cace-footer-white.svg" />
        </div>

        {isCommonPage ? (
          <div>
            <div className={styles.footerWrapRs}>
              <a className={styles.footerRsLink} href="#">
                <Instagram className={styles.footerRsLinkIcon} />
              </a>
              <a className={styles.footerRsLink} href="#">
                <LinkedIn className={styles.footerRsLinkIcon} />
              </a>
              <ToggleTheme />
            </div>
            <div className={styles.footerWrapLinks}>
              <Link className={styles.footerLink} to="/shopify">
                Shopify
              </Link>
              <Link className={styles.footerLink} to="/headless">
                Headless
              </Link>
              <Link className={styles.footerLink} to="/testimonios">
                Testimonios
              </Link>
              <Link className={styles.footerLink} to="/presupuesto">
                Contacto
              </Link>
            </div>
          </div>
        ) : (
          <div className={styles.footerEstimateEmails}>
            <EstimateItem
              link="mailto:hola@owlstudio.xyz"
              text="hola@owlstudio.xyz"
              title="Nuevo proyecto"
            />
            <EstimateItem
              link="mailto:rrhh@owlstudio.xyz"
              text="rrhh@owlstudio.xyz"
              title="Sumate a Owl"
            />
          </div>
        )}
      </div>

      <p className={styles.footerCopyright}>
        <span>Desde Buenos Aires 🇦🇷 al mundo 🌎</span>
        <span>
          &copy; Copyright | Owl Studio 2012 - {new Date().getFullYear()} | Todos los derechos
          reservados
        </span>
      </p>
    </footer>
  );
};
