import React, { useEffect } from "react";

import cn from "clsx";
import { PageRendererProps } from "gatsby";

import useIsTouchDevice from "../hooks/use-is-touch-device";
import useStore from "../store";
import {
  baseTheme,
  fontSizeTheme,
  colorsTheme,
  spaceTheme,
  containerStyle,
} from "../styles/global.css";
import { Cursor } from "../ui/cursor";
import { Footer } from "./footer";
import { Header } from "./header";
import { Seo } from "./seo";

export const Layout: React.FC<PageRendererProps> = ({ children, location }) => {
  const isTouchDevice = useIsTouchDevice();

  /*** Navbar ***/

  const { menuIsLocked, setMenuIsLocked } = useStore((store) => ({
    menuIsLocked: store.menuIsLocked,
    setMenuIsLocked: store.setMenuIsLocked,
  }));

  useEffect(() => {
    const timmer = setTimeout(() => setMenuIsLocked(false), 10);

    return () => {
      clearTimeout(timmer);
    };
  }, [menuIsLocked, setMenuIsLocked]);

  return (
    <div className={cn(baseTheme, colorsTheme, spaceTheme, fontSizeTheme, containerStyle)}>
      <Seo />
      {!isTouchDevice && <Cursor pathname={location.pathname} />}
      <Header pathname={location.pathname} />
      <main>{children}</main>
      <Footer pathname={location.pathname} />
    </div>
  );
};
