import { useLayoutEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useLayoutEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updatePosition, false);
    updatePosition();

    return () => {
      window.removeEventListener("scroll", updatePosition, false);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
