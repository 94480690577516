import React, { useCallback, useEffect, useRef, useState } from "react";

import cn from "clsx";
import gsap from "gsap";

import useCursorHoverListener from "../../hooks/use-cursor-hover-listener";
import s from "./cursor.css";

interface Props {
  pathname: string;
}

export const Cursor: React.FC<Props> = ({ pathname }) => {
  const cursor = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(true);
  const [isClick, setIsClick] = useState(false);
  const [hasMoved, setHasMoved] = useState(false);

  const [isBlueOpacity, setIsBlueOpacity] = useCursorHoverListener(
    pathname,
    ".cursor-blue-opacity",
  );

  const [isWhiteOpacity, setIsWhiteOpacity] = useCursorHoverListener(
    pathname,
    ".cursor-white-opacity",
  );

  const [isMaximized, setIsMaximized] = useCursorHoverListener(
    pathname,
    ".cursor-maximized",
    "hidden-cursor",
  );

  const onMouseMove = useCallback(({ clientX, clientY }) => {
    gsap.to(cursor.current, {
      x: clientX,
      y: clientY,
      duration: 0,
      ease: "expo.out",
    });
    setHasMoved(true);
    setIsHidden(false);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove, false);

    return () => {
      window.removeEventListener("mousemove", onMouseMove, false);
    };
  }, [onMouseMove, hasMoved]);

  // onMouseEnter, onMouseLeave, onMouseDown, onMouseUp

  useEffect(() => {
    const onMouseEnter = () => {
      setIsHidden(false);
      setIsBlueOpacity(false);
      setIsWhiteOpacity(false);
      setIsMaximized(false);
    };

    const onMouseLeave = () => {
      setIsHidden(true);
    };

    const onMouseDown = () => {
      setIsClick(true);
    };

    const onMouseUp = () => {
      setIsClick(false);
    };

    document.addEventListener("mouseenter", onMouseEnter, false);
    document.addEventListener("mouseleave", onMouseLeave, false);
    document.addEventListener("mousedown", onMouseDown, false);
    document.addEventListener("mouseup", onMouseUp, false);
    document.addEventListener("contextmenu", onMouseLeave, false);

    return () => {
      document.removeEventListener("mouseenter", onMouseEnter, false);
      document.removeEventListener("mouseleave", onMouseLeave, false);
      document.removeEventListener("mousedown", onMouseDown, false);
      document.removeEventListener("mouseup", onMouseUp, false);
      document.removeEventListener("contextmenu", onMouseLeave, false);
    };
  }, [setIsBlueOpacity, setIsWhiteOpacity, setIsMaximized]);

  return (
    <div className={s.container} style={{ opacity: hasMoved ? 1 : 0 }}>
      <div ref={cursor}>
        <div
          className={cn(
            s.cursor,
            isHidden && "hidden",
            isClick && "click",
            isBlueOpacity && "blue-opacity",
            isWhiteOpacity && "white-opacity",
            isMaximized && "maximized",
          )}
        >
          {/* <RocketLaunchOutline className={cn(s.content, s.svg)} /> */}
          <span className={s.content}>Click!</span>
        </div>
      </div>
    </div>
  );
};
