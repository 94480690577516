import React from "react";

import cn from "clsx";
import { Link } from "gatsby";

import useNavbar from "../../hooks/use-navbar";
import ChevronDown from "../../ui/icons/ChevronDown";
import ChevronForward from "../../ui/icons/ChevronForward";
import styles from "./header.css";

interface Props {
  text: string;
  link: string;
  submenu?: {
    text: string;
    link: string;
    description: string;
  }[];
}

export const NavbarItem: React.FC<Props> = ({ text, link, submenu }) => {
  const { menuIsLocked, closeMenu } = useNavbar();

  return (
    <li className={styles.navbarItem}>
      <Link
        className={cn(styles.navbarItemLink, "cursor-blue-opacity")}
        to={link}
        onClick={closeMenu}
      >
        {text}
        {submenu && <ChevronDown className={styles.navbarItemChevron} />}
      </Link>

      {submenu && (
        <div className={cn(styles.navbarSubMenu, menuIsLocked && "hidden")}>
          {submenu.map((item) => (
            <Link
              key={item.text}
              className={cn(styles.navbarSubMenuLink, "cursor-white-opacity")}
              to={item.link}
              onClick={closeMenu}
            >
              <div>
                <span className={styles.navbarSubMenuTitle}>{item.text}</span>
                {item.description ? (
                  <span className={styles.navbarSubMenuDescription}>{item.description}</span>
                ) : null}
              </div>
              <div>
                <ChevronForward className={styles.navbarSubMenuChevron} />
              </div>
            </Link>
          ))}
        </div>
      )}
    </li>
  );
};
