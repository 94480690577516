import React from "react";

const ChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 512 512" {...props}>
    <title>Chevron Down</title>
    <path
      d="m112 184 144 144 144-144"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
    />
  </svg>
);

export default ChevronDown;
