import React from "react";

import MailOutline from "../../ui/icons/MailOutline";
import styles from "./footer.css";

interface Props {
  title: string;
  text: string;
  link: string;
}

export const EstimateItem: React.FC<Props> = ({ title, text, link }) => {
  return (
    <div className={styles.footerEstimateItem}>
      <span className={styles.footerEstimateItemText}>{title}</span>
      <div className={styles.footerEstimateItemEmail}>
        <MailOutline className={styles.footerEstimateItemIcon} />
        <a className={styles.footerEstimateItemLink} href={link}>
          {text}
        </a>
      </div>
    </div>
  );
};
