import React from "react";

const MailOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 512 512" {...props}>
    <title>Mail</title>
    <rect
      fill="none"
      height={320}
      rx={40}
      ry={40}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={38}
      width={416}
      x={48}
      y={96}
    />
    <path
      d="m112 160 144 112 144-112"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={38}
    />
  </svg>
);

export default MailOutline;
