import { useEffect, useState } from "react";

const useCursorHoverListener = (
  pathname: string,
  selectors: any,
  hoverClass?: string,
): [isHovered: boolean, setIsHovered: (isHovered: boolean) => void] => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    const elements: NodeListOf<Element> = document.querySelectorAll(selectors);

    const mouseEnter = () => {
      if (hoverClass) document.documentElement.classList.add(hoverClass);
      setIsHovered(true);
    };

    const mouseLeave = () => {
      if (hoverClass) document.documentElement.classList.remove(hoverClass);
      setIsHovered(false);
    };

    elements.forEach((element) => {
      element.addEventListener("mouseenter", mouseEnter, false);
      element.addEventListener("mouseleave", mouseLeave, false);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("mouseenter", mouseEnter, false);
        element.removeEventListener("mouseleave", mouseLeave, false);
      });
    };
  }, [pathname, hoverClass, selectors]);

  useEffect(() => {
    if (hoverClass && !isHovered) document.documentElement.classList.remove(hoverClass);
  }, [hoverClass, isHovered]);

  return [isHovered, setIsHovered];
};

export default useCursorHoverListener;
