import { lockedScroll, unlockedScroll } from "../misc/util";
import useStore from "../store";

const useNavbar = () => {
  const menuIsOpen = useStore(({ menuIsOpen }) => menuIsOpen);
  const setMenuIsOpen = useStore(({ setMenuIsOpen }) => setMenuIsOpen);

  const menuIsLocked = useStore(({ menuIsLocked }) => menuIsLocked);
  const setMenuIsLocked = useStore(({ setMenuIsLocked }) => setMenuIsLocked);

  const toggleMenu = () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
      unlockedScroll(800);
    } else {
      setMenuIsOpen(true);
      lockedScroll();
    }
  };

  const closeMenu = (event: any) => {
    setMenuIsOpen(false);
    unlockedScroll(800);
    if (event.target.ariaCurrent === null) setMenuIsLocked(true);
  };

  return {
    menuIsOpen,
    menuIsLocked,
    toggleMenu,
    closeMenu,
  };
};

export default useNavbar;
