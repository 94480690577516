import React from "react";

import { GatsbyLinkProps, Link } from "gatsby";

type Props = Omit<GatsbyLinkProps<Record<string, unknown>>, "ref">;

export const IsomorphicLink: React.FC<Props> = ({ children, ...props }) => {
  const internal = /^(\/|\#)(?!\/)/.test(props.to);

  if (internal) {
    return <Link {...props}>{children}</Link>;
  }

  return (
    <a href={props.to} rel="noreferrer" target="_blank" {...props}>
      {children}
    </a>
  );
};
