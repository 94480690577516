import { create } from "zustand";

export interface Store {
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: Store["menuIsOpen"]) => void;

  menuIsLocked: boolean;
  setMenuIsLocked: (changePage: Store["menuIsLocked"]) => void;
}

const useStore = create<Store>((set) => ({
  menuIsOpen: false,
  setMenuIsOpen: (menuIsOpen) => set({ menuIsOpen }),

  menuIsLocked: false,
  setMenuIsLocked: (menuIsLocked) => set({ menuIsLocked }),
}));

export default useStore;
