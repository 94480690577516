exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-headless-tsx": () => import("./../../../src/pages/headless.tsx" /* webpackChunkName: "component---src-pages-headless-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-presupuesto-tsx": () => import("./../../../src/pages/presupuesto.tsx" /* webpackChunkName: "component---src-pages-presupuesto-tsx" */),
  "component---src-pages-shopify-tsx": () => import("./../../../src/pages/shopify.tsx" /* webpackChunkName: "component---src-pages-shopify-tsx" */),
  "component---src-pages-testimonios-tsx": () => import("./../../../src/pages/testimonios.tsx" /* webpackChunkName: "component---src-pages-testimonios-tsx" */)
}

