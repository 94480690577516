import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

import ogImageDefault from "../images/og-image.jpg";
import { isBrowser } from "../misc/util";

interface Props {
  title?: string;
  description?: string;
  image?: string;
}

export const Seo: React.FC<Props> = ({ title, description, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query QuerySEO {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `,
  );

  const metaTitle = `${title || site.siteMetadata.title} | Owl Studio`;
  const metaDescription = description || site.siteMetadata.description;
  const url = isBrowser ? window.location.href : site.siteMetadata.siteUrl;

  const ogImage = image || ogImageDefault;

  return (
    <Helmet
      htmlAttributes={{ lang: "es" }}
      link={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
      meta={[
        {
          charSet: "utf-8",
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:locale",
          content: "es",
        },
        {
          property: "og:site_name",
          content: "Owl Studio",
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: ogImage,
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:site",
          content: "@owl-studio",
        },
        {
          property: "twitter:creator",
          content: "@owl-studio",
        },
        {
          property: "twitter:title",
          content: metaTitle,
        },
        {
          property: "twitter:domain",
          content: site.siteMetadata.siteUrl,
        },
        {
          property: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:url",
          content: url,
        },
        {
          property: "twitter:image",
          content: ogImage,
        },
      ]}
      title={metaTitle}
    />
  );
};
