import React from "react";

import cn from "clsx";
import { GatsbyLinkProps } from "gatsby";

import useMediaQuery from "../../hooks/use-media-query";
import useScrollTo from "../../hooks/use-scroll-to";
import { IsomorphicLink } from "../isomorphic-link";
import { Loading } from "../loading";
import styles from "./button-special.css";

interface ButtonSpecial extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref"> {
  text: string;
  variant?: "solid" | "outline" | "invert";
}

export const ButtonSpecial: React.FC<ButtonSpecial> = ({ text, variant = "solid", ...props }) => {
  return (
    <IsomorphicLink
      {...props}
      className={cn(
        styles.button[variant],
        variant === "solid" ? "cursor-white-opacity" : "cursor-blue-opacity",
      )}
    >
      <span className={styles.buttonText[variant]}>{text}</span>
    </IsomorphicLink>
  );
};

interface ButtonClick extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant?: "solid" | "outline" | "invert";
  isDisabled?: boolean;
  isLoading?: boolean;
  overlay?: false | string;
}

export const ButtonClickSpecial: React.FC<ButtonClick> = ({
  text,
  variant = "solid",
  isDisabled = false,
  isLoading = false,
  overlay = false,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn(
        styles.button[variant],
        variant === "solid" ? "cursor-white-opacity" : "cursor-blue-opacity",
        isDisabled && styles.buttonDisabled,
        isLoading && styles.buttonLoading,
        overlay && !isLoading && styles.buttonOverlay,
        props.className,
      )}
    >
      <span className={cn(styles.buttonText[variant], styles.label)}>{text}</span>
      <span className={styles.loading}>
        <Loading />
      </span>
      <span className={cn(styles.buttonText[variant], styles.overlay)}>{overlay}</span>
    </button>
  );
};

interface ButtonAnchor extends Omit<ButtonClick, "onClick"> {
  anchor: string;
}

export const ButtonAnchorSpecial: React.FC<ButtonAnchor> = ({ anchor, ...props }) => {
  const scrollTo = useScrollTo();
  const isMatch = useMediaQuery("(max-width: 992px)");

  return <ButtonClickSpecial {...props} onClick={() => scrollTo(anchor, isMatch ? 0 : -60)} />;
};
