import React from "react";

const ChevronForward: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 512 512" {...props}>
    <title>Chevron Forward</title>
    <path
      d="m184 112 144 144-144 144"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
    />
  </svg>
);

export default ChevronForward;
