export const noop = () => {};

export const isBrowser = typeof window !== "undefined";

export const isNavigator = typeof navigator !== "undefined";

export const lockedScroll = () => {
  document.body.classList.add("locked-scroll");
};

export const unlockedScroll = (ms?: number) => {
  setTimeout(() => document.body.classList.remove("locked-scroll"), ms);
};
