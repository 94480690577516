import { useEffect, useState } from "react";

import { isBrowser } from "../misc/util";

const getInitialState = (query: string, defaultState?: boolean): boolean => {
  if (defaultState !== undefined) {
    return defaultState;
  }

  if (isBrowser) {
    return window.matchMedia(query).matches;
  }

  return false;
};

const useMediaQuery = (query: string, defaultState?: boolean): boolean => {
  const [isMatch, setIsMatch] = useState<boolean>(() => getInitialState(query, defaultState));

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setIsMatch(!!mql.matches);
    };

    mql.addEventListener("change", onChange);
    setIsMatch(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener("change", onChange);
    };
  }, [query]);

  return isMatch;
};

export default useMediaQuery;
